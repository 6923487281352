export const APP_NAME = "scambleXYZ";
export const SPONSOR = "W3 Ventures";
export const COMPANY_NAME = "W3 Ventures LLC";
export const TWITTER_URL = "https://x.com/ted_slocum";
export const GITHUB_URL = "https://github.com/sted9000/scambling-xyz";
export const CTA_TITLE = "Never Miss a Sweepstakes Reward Again";
export const CTA_DESCRIPTION =
  "Check in, climb the leaderboard, and track your rewards while staying connected to the sweepstakes community.";
export const SELLING_POINT = "It's Totally Free!";
export const CTA_SECONDARY_LINK =
  "https://github.com/sted9000/vue-startup-template";
export const sites = {
  "SITE#0": {
    id: 0,
    name: "chumba",
    fullName: "Chumba",
    url: "https://lobby.chumbacasino.com/",
    imagePath: "/images/icon-chumba.png",
    isCard: true,
    cssColor: "#FF6B6B",
    tailwindColor: "bg-red-400",
  },
  "SITE#1": {
    id: 1,
    name: "wow",
    fullName: "WowVegas",
    url: "https://wowvegas.com/lobby",
    imagePath: "/images/icon-wow.png",
    isCard: true,
    cssColor: "#4ECDC4",
    tailwindColor: "bg-teal-400",
  },
  "SITE#2": {
    id: 2,
    name: "sportzino",
    fullName: "Sportzino",
    url: "https://sportzino.com/casino-lobby/lobby",
    imagePath: "/images/icon-sportzino.ico",
    isCard: false,
    cssColor: "#45B7D1",
    tailwindColor: "bg-blue-400",
  },
  "SITE#3": {
    id: 3,
    name: "zula",
    fullName: "Zula Casino",
    url: "https://www.zulacasino.com/lobby",
    imagePath: "/images/icon-zula.ico",
    isCard: false,
    cssColor: "#F7B731",
    tailwindColor: "bg-yellow-400",
  },
  "SITE#4": {
    id: 4,
    name: "global",
    fullName: "Global Poker",
    url: "https://play.globalpoker.com/",
    imagePath: "/images/icon-global.png",
    isCard: true,
    cssColor: "#A3A1FB",
    tailwindColor: "bg-indigo-400",
  },
  "SITE#5": {
    id: 5,
    name: "spree",
    fullName: "Spree",
    url: "https://spree.com/slots",
    imagePath: "/images/icon-spree.ico",
    isCard: false,
    cssColor: "#26DE81",
    tailwindColor: "bg-green-400",
  },
  "SITE#7": {
    id: 7,
    name: "ding",
    fullName: "DingDingDing",
    url: "https://dingdingding.com/lobby/",
    imagePath: "/images/icon-ding.ico",
    isCard: false,
    cssColor: "#FC5C65",
    tailwindColor: "bg-pink-400",
  },
  "SITE#8": {
    id: 8,
    name: "sweeps",
    fullName: "Sweeps Slots",
    url: "https://www.sweepslots.com/",
    imagePath: "/images/icon-sweeps.ico",
    isCard: false,
    cssColor: "#5352ED",
    tailwindColor: "bg-purple-400",
  },
  "SITE#9": {
    id: 9,
    name: "realprize",
    fullName: "Real Prize",
    url: "https://realprize.com/#",
    imagePath: "/images/icon-real.png",
    isCard: false,
    cssColor: "#2C3E50",
    tailwindColor: "bg-gray-700",
  },
  "SITE#10": {
    id: 10,
    name: "rolling",
    fullName: "Rolling Riches",
    url: "https://www.rollingriches.com/",
    imagePath: "/images/icon-rolling.png",
    isCard: false,
    cssColor: "#16A085",
    tailwindColor: "bg-green-600",
  },
  "SITE#11": {
    id: 11,
    name: "pulsz",
    fullName: "Pulsz",
    url: "https://www.pulsz.com/home",
    imagePath: "/images/icon-pulsz.ico",
    isCard: true,
    cssColor: "#8E44AD",
    tailwindColor: "bg-purple-600",
  },
  "SITE#12": {
    id: 12,
    name: "stackr",
    fullName: "Stackr Casino",
    url: "https://www.stackrcasino.com/",
    imagePath: "/images/icon-stackr.png",
    isCard: false,
    cssColor: "#D35400",
    tailwindColor: "bg-orange-600",
  },
  "SITE#13": {
    id: 13,
    name: "scratchful",
    fullName: "Scratchful",
    url: "https://www.scratchful.com/home",
    imagePath: "/images/icon-scratchful.png",
    isCard: false,
    cssColor: "#27AE60",
    tailwindColor: "bg-green-500",
  },
  "SITE#14": {
    id: 14,
    name: "funrize",
    fullName: "Funrize",
    url: "https://funrize.com/en/",
    imagePath: "/images/icon-funrize.ico",
    isCard: false,
    cssColor: "#3498DB",
    tailwindColor: "bg-blue-500",
  },
  "SITE#15": {
    id: 15,
    name: "luckyland",
    fullName: "Luckyland",
    url: "https://www.luckylandslots.com/",
    imagePath: "/images/icon-luckyland.ico",
    isCard: true,
    cssColor: "#E74C3C",
    tailwindColor: "bg-red-500",
  },
  "SITE#16": {
    id: 16,
    name: "mcluck",
    fullName: "McLuck",
    url: "https://www.mcluck.com/home",
    imagePath: "/images/icon-mcluck.ico",
    isCard: true,
    cssColor: "#9B59B6",
    tailwindColor: "bg-purple-500",
  },
  "SITE#17": {
    id: 17,
    name: "moonspin",
    fullName: "MoonSpin",
    url: "https://moonspin.us/home",
    imagePath: "/images/icon-moonspin.ico",
    isCard: false,
    cssColor: "#34495E",
    tailwindColor: "bg-gray-600",
  },
  "SITE#18": {
    id: 18,
    name: "modo",
    fullName: "Modo",
    url: "https://modo.us/lobby",
    imagePath: "/images/icon-modo.ico",
    isCard: true,
    cssColor: "#1ABC9C",
    tailwindColor: "bg-teal-500",
  },
  "SITE#19": {
    id: 19,
    name: "golden_hearts",
    fullName: "Golden Hearts",
    url: "https://www.goldenheartsgames.com/login",
    imagePath: "/images/icon-golden.png",
    isCard: false,
    cssColor: "#F1C40F",
    tailwindColor: "bg-yellow-500",
  },
  "SITE#20": {
    id: 20,
    name: "high_5",
    fullName: "High 5",
    url: "https://fun.high5casino.com/gc",
    imagePath: "/images/icon-high5.ico",
    isCard: true,
    cssColor: "#2980B9",
    tailwindColor: "bg-blue-600",
  },
  "SITE#21": {
    id: 21,
    name: "stake",
    fullName: "Stake",
    url: "https://stake.us/",
    imagePath: "/images/icon-stake.png",
    isCard: false,
    cssColor: "#C0392B",
    tailwindColor: "bg-red-700",
  },
  "SITE#22": {
    id: 22,
    name: "fortune",
    fullName: "Fortune Coins",
    url: "https://www.fortunecoins.com",
    imagePath: "/images/icon-fortune.ico",
    isCard: false,
    cssColor: "#F39C12",
    tailwindColor: "bg-yellow-600",
  },
  "SITE#23": {
    id: 23,
    name: "crown",
    fullName: "Crown Casino",
    url: "https://crowncoinscasino.com/",
    imagePath: "/images/icon-crown.png",
    isCard: false,
    cssColor: "#7F8C8D",
    tailwindColor: "bg-gray-500",
  },
  "SITE#24": {
    id: 24,
    name: "pulszbingo",
    fullName: "Pulsz Bingo",
    url: "https://www.pulszbingo.com/",
    imagePath: "/images/icon-pulszbingo.ico",
    isCard: false,
    cssColor: "#9B59B6",
    tailwindColor: "bg-purple-500",
  },
  "SITE#25": {
    id: 25,
    name: "smiles",
    fullName: "Smiles",
    url: "https://smilescasino.com/",
    imagePath: "/images/icon-smiles.png",
    isCard: false,
    cssColor: "#F1C40F",
    tailwindColor: "bg-yellow-500",
  },
  "SITE#26": {
    id: 26,
    name: "luckybird",
    fullName: "Lucky Bird",
    url: "https://luckybird.io/",
    imagePath: "/images/icon-luckybird.ico",
    isCard: false,
    cssColor: "#E74C3C",
    tailwindColor: "bg-red-500",
  },
  "SITE#27": {
    id: 27,
    name: "clubspoker",
    fullName: "Clubs Poker",
    url: "https://play.clubs.poker/",
    imagePath: "/images/icon-clubspoker.ico",
    isCard: false,
    cssColor: "#8E44AD",
    tailwindColor: "bg-purple-700",
  },
  "SITE#28": {
    id: 28,
    name: "jackpota",
    fullName: "Jackpota",
    url: "https://jackpota.com/",
    imagePath: "/images/icon-jackpota.ico",
    isCard: false,
    cssColor: "#F39C12",
    tailwindColor: "bg-yellow-600",
  },
  "SITE#29": {
    id: 29,
    name: "megabonanza",
    fullName: "Mega Bonanza",
    url: "https://www.megabonanza.com/home/",
    imagePath: "/images/icon-megabonanza.ico",
    isCard: false,
    cssColor: "#3498DB",
    tailwindColor: "bg-blue-500",
  },
};
